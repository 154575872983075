(function() {
    // Don't bother with this code if the new dialect system is present
    if (Discourse.dialect_deprecated) { return; }

    Discourse.Dialect.inlineBetween({
        start: '\\(',
        stop: '\\)',
        rawContents: true,
        emitter: function (contents) {
            return '\\(' + contents + '\\)';
        }
    });

    Discourse.Dialect.inlineBetween({
        start: '\\[',
        stop: '\\]',
        rawContents: true,
        emitter: function (contents) {
            return '\\[' + contents + '\\]';
        }
    });

    Discourse.Dialect.inlineBetween({
        start: '$$',
        stop: '$$',
        rawContents: true,
        emitter: function (contents) {
        return '$$' + contents + '$$';
        }
    });


    Discourse.Dialect.inlineBetween({
        start: '$',
        stop: '$',
        rawContents: true,
        emitter: function (contents) {
            return '$' + contents + '$';
        }
    });

    Discourse.Dialect.inlineRegexp({
        start: '\\begin',
        matcher: /(\\begin{[\S\s]+})([\S\s]*)(\\end{[\S\s]+})/,
        emitter: function (matches) {
            return matches[0];
        }
    });

    //these last two are to make asciimath support possible

    Discourse.Dialect.inlineBetween({
        start: 'ˊˊ',
        stop: 'ˊˊ',
        rawContents: true,
        emitter: function (contents) {
            return 'ˊˊ' + contents + 'ˊˊ';
        }
    });

    Discourse.Dialect.inlineBetween({
        start: 'ˊ',
        stop: 'ˊ',
        rawContents: true,
        emitter: function (contents) {
            return 'ˊ' + contents + 'ˊ';
        }
    });
})();
define("discourse/plugins/MathJax support for Discourse/initializers/mathjax", ["exports", "discourse/lib/plugin-api", "discourse/lib/load-script"], function (_exports, _pluginApi, _loadScript) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /* global MathJax */
  function oldApplyBody() {
    MathJax.Hub.Queue(["Typeset", MathJax.Hub, "topic"]);
  }

  function oldApplyPreview() {
    MathJax.Hub.Queue(["Typeset", MathJax.Hub, "wmd-preview"]); // if the caret is on the last line ensure preview scrolled to bottom

    var caretPosition = Discourse.Utilities.caretPosition(this.wmdInput[0]);

    if (!this.wmdInput.val().substring(caretPosition).match(/\\n/)) {
      var $wmdPreview = $('#wmd-preview');

      if ($wmdPreview.is(':visible')) {
        $wmdPreview.scrollTop($wmdPreview[0].scrollHeight);
      }
    }
  }

  function mathJaxConfig() {
    MathJax.Hub.Config({
      "HTML-CSS": {
        preferredFont: "TeX",
        availableFonts: ["TeX"],
        linebreaks: {
          automatic: true
        },
        EqnChunk: MathJax.Hub.Browser.isMobile ? 10 : 50
      },
      tex2jax: {
        inlineMath: [["$", "$"], ["\\(", "\\)"]],
        displayMath: [["$$", "$$"], ["\\[", "\\]"]],
        processEscapes: true
      },
      TeX: {
        noUndefined: {
          attributes: {
            mathcolor: "red",
            mathbackground: "#FFEEEE",
            mathsize: "90%"
          }
        },
        Macros: {
          href: "{}"
        }
      },
      messageStyle: "none"
    });
  }

  function oldCode(container) {
    var siteSettings = container.lookup('site-settings:main');

    if (!siteSettings.enable_mathjax_plugin) {
      return;
    }

    (0, _loadScript.default)(siteSettings.mathjax_url + '?config=' + siteSettings.mathjax_config, {
      scriptTag: true
    }).then(function () {
      mathJaxConfig();
      (0, _pluginApi.decorateCooked)(container, oldApplyBody);
      container.lookupFactory('view:composer').prototype.on("previewRefreshed", oldApplyPreview);
    });
  }

  function initializePlugin(api) {
    var container = api.container;
    var siteSettings = container.lookup('site-settings:main');

    if (!siteSettings.enable_mathjax_plugin) {
      return;
    }

    (0, _loadScript.default)(siteSettings.mathjax_url + '?config=' + siteSettings.mathjax_config, {
      scriptTag: true
    }).then(function () {
      mathJaxConfig();
      api.decorateCooked(function ($html) {
        return $.each($html, function (i, domNode) {
          return MathJax.Hub.Queue(["Typeset", MathJax.Hub, domNode]);
        });
      });
    });
  }

  var _default = {
    name: 'discourse-mathjax',
    after: 'inject-objects',
    initialize: function initialize(container) {
      (0, _pluginApi.withPluginApi)('0.1', function (api) {
        return initializePlugin(api);
      }, {
        noApi: function noApi() {
          return oldCode(container);
        }
      });
    }
  };
  _exports.default = _default;
});

